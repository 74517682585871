import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'

import { Controller, useForm, useFieldArray } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import { Label, LoadingBox, SuccessButton } from 'components'
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import {
  Save as SaveIcon,
  PlusCircle as PlusIcon,
  MinusCircle as MinusIcon,
} from 'react-feather'
import { isEmpty } from 'lodash'
import schema from './schema'
import * as service from 'service'
import { routes } from 'Routes'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'

const PartnerServicesForm = ({ partnerService }) => {
  const history = useHistory()
  const snackbar = useSnackbar()
  const classes = useStyles()
  const [selectedCompanyService, setSelectedCompanyService] = useState(
    partnerService?.companyService
      ? {
          value: partnerService.companyService.id,
          label: partnerService.companyService.name,
        }
      : null,
  )
  const [selectedServiceCategory, setSelectedServiceCategory] = useState(
    partnerService?.companyService?.serviceCategory
      ? {
          value: partnerService?.companyService?.serviceCategory.id,
          label: partnerService?.companyService?.serviceCategory.name,
        }
      : null,
  )
  const [loading, setLoading] = useState(false)
  const [companyServices, setCompanyServices] = useState([])
  const [serviceCategories, setServiceCategories] = useState([])
  const [unformattedCompanyServices, setUnformattedCompanyServices] = useState(
    [],
  )
  const [unformattedServicesCategories, setUnformattedServicesCategories] =
    useState([])

  const { control, handleSubmit, errors, reset, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      serviceCategory: partnerService?.companyService?.serviceCategory
        ? {
            value: partnerService?.companyService?.serviceCategory?.id || '',
            label: partnerService?.companyService?.serviceCategory?.name || '',
          }
        : null,
      companyServiceId: {
        value: partnerService?.companyService?.id || '',
        label: partnerService?.companyService?.name || '',
      },
      description: partnerService?.description || '',
      payment_type: partnerService?.partnerServicePrices[0]?.paymentType || '',
      prices: partnerService?.partnerServicePrices || [
        {
          priceId: '',
          price: '',
          quantity: '',
        },
      ],
      attributes: partnerService?.resources?.attributes || [''],
    },
  })

  const {
    fields: priceFields,
    append: appendPrice,
    remove: removePrice,
  } = useFieldArray({
    control,
    name: 'prices',
  })

  const {
    fields: attributesFields,
    append: appendAttribute,
    remove: removeAttribute,
  } = useFieldArray({
    control,
    name: 'attributes',
  })

  const handleAppend = () => {
    if (attributesFields.length < 10) {
      appendAttribute('')
    }
  }

  const formatOptionsForAutocomplete = (options) => {
    return [
      ...options.map((option) => {
        return { value: option.id, label: option.name }
      }),
    ]
  }
  const loadCompanyServices = async () => {
    const serviceCategoryId =
      selectedServiceCategory?.id ||
      partnerService?.companyService?.serviceCategory?.id

    const responseCompanyServices =
      await service.marketPlace.companyService.get({ serviceCategoryId })

    setCompanyServices(
      formatOptionsForAutocomplete(
        responseCompanyServices?.data?.companyServices,
      ),
    )

    setUnformattedCompanyServices(
      responseCompanyServices?.data?.companyServices,
    )
  }

  useEffect(() => {
    const loadCategories = async () => {
      setLoading(true)
      const responseServiceCategories =
        await service.marketPlace.serviceCategories.get({})

      setServiceCategories(
        formatOptionsForAutocomplete(
          responseServiceCategories?.data?.serviceCategories,
        ),
      )
      setUnformattedServicesCategories(
        responseServiceCategories?.data?.serviceCategories,
      )

      if (partnerService) {
        await loadCompanyServices()
      }
      setLoading(false)
    }

    loadCategories()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (watch('serviceCategory')) {
      loadCompanyServices()
    }
    // eslint-disable-next-line
  }, [watch('serviceCategory')])

  const serviceCategory = partnerService?.companyService?.serviceCategory
    ? {
        value: partnerService.companyService.serviceCategory.id,
        label: partnerService.companyService.serviceCategory.name,
      }
    : null

  const companyService = partnerService?.companyService
    ? {
        value: partnerService.companyService.id,
        label: partnerService.companyService.name,
      }
    : null

  const partnerServiceId = partnerService?.id

  const onSubmit = (data) => {
    setLoading(true)

    if (partnerServiceId) {
      handleEdit(data)
    } else {
      handleCreate(data)
    }
  }

  const handleCreate = async (data) => {
    try {
      await service.marketPlace.partnerService.create({
        partnerService: {
          ...data,
          companyServiceId: data.companyServiceId.value,
        },
      })

      setLoading(false)
      snackbar.open({
        message: 'Serviço de empresa criado com sucesso',
        variant: 'success',
      })
    } catch (error) {
      handleError(error, data)
    } finally {
      history.push(routes.services.all)
    }
  }

  const handleEdit = async (data) => {
    try {
      if (
        partnerService.status !==
        constants.partnerService.WAITING_APPROVAL_STATUS
      ) {
        await service.marketPlace.partnerService.update({
          partnerServiceId,
          partnerService: { ...data },
        })
      }

      setLoading(false)
      snackbar.open({
        message: 'Serviço de empresa atualizado com sucesso',
        variant: 'success',
      })
    } catch (error) {
      handleError(error, data)
    } finally {
      history.push(routes.services.all)
    }
  }

  const handleError = (error, data) => {
    setLoading(false)
    reset(data)
    snackbar.open({
      message: helpers.formatters.errorMessage(error),
      variant: 'error',
    })
  }

  const handleChangeCompanyService = (value) => {
    let option = unformattedCompanyServices.find(
      (element) => element.name === value,
    )
    setSelectedCompanyService(option)
  }

  const handleChangeServiceCategory = (value) => {
    let option = unformattedServicesCategories.find(
      (element) => element.name === value,
    )
    setSelectedServiceCategory(option)
  }

  const selectOptions = [
    { value: 'fixed', label: 'Preço fixo', id: 'unique-01' },
    { value: 'monthly', label: 'Mensalidade', id: 'unique-02' },
    { value: 'demand', label: 'Faixa de preço por demanda', id: 'unique-03' },
  ]

  const isDemandPrice = watch('payment_type') === 'demand'

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box width="100%" px={3} pb={10}>
            <Grid container spacing={4}>
              {!isEmpty(serviceCategories) && (
                <Grid item xs={12}>
                  <Controller
                    as={
                      <Autocomplete
                        disabled={serviceCategory !== null}
                        options={serviceCategories?.map((val) => val)}
                        defaultValue={
                          serviceCategory || selectedServiceCategory
                        }
                        getOptionLabel={(option) =>
                          option.label ? option.label : ''
                        }
                        getOptionSelected={(option, value) =>
                          option.value === value?.value ||
                          value?.value === serviceCategory?.value ||
                          value?.value === selectedServiceCategory?.value
                        }
                        noOptionsText="Nenhum novo serviço disponível no momento."
                        onInputChange={(event, newInputValue) => {
                          handleChangeServiceCategory(newInputValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={!!errors?.serviceCategory}
                            helperText={
                              errors?.serviceCategory
                                ? 'Selecione uma categoria'
                                : ''
                            }
                            label="Categoria de serviços"
                          />
                        )}
                      />
                    }
                    control={control}
                    rules={{ required: true }}
                    name="serviceCategory"
                    onChange={([, data]) => data}
                    mode="onChange"
                  />
                </Grid>
              )}
              {!isEmpty(companyServices) && !isEmpty(watch('serviceCategory')) && (
                <Grid item xs={12}>
                  <Controller
                    as={
                      <Autocomplete
                        disabled={companyService !== null}
                        defaultValue={companyService}
                        options={companyServices?.map((val) => val)}
                        getOptionLabel={(option) =>
                          option.label ? option.label : ''
                        }
                        getOptionSelected={(option, value) =>
                          option.value === value?.value ||
                          value?.value === companyService?.value
                        }
                        noOptionsText="Nenhum novo serviço disponível no momento."
                        onInputChange={(event, newInputValue) => {
                          handleChangeCompanyService(newInputValue)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            error={!!errors?.companyServiceId}
                            helperText={
                              errors?.companyServiceId
                                ? 'Selecione um serviço'
                                : ''
                            }
                            label="Serviço da empresa"
                          />
                        )}
                      />
                    }
                    control={control}
                    rules={{ required: true }}
                    name="companyServiceId"
                    onChange={([, data]) => data}
                    mode="onChange"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      multiline
                      error={!!errors.description}
                      helperText={errors?.description?.message}
                      minRows={6}
                      label="Descreva o serviço"
                    />
                  }
                  control={control}
                  name="description"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                {attributesFields.map((option, index) => (
                  <Box key={index + 1000} className={classes.rowContainer}>
                    <Controller
                      as={
                        <TextField
                          fullWidth
                          variant="outlined"
                          label="Descreva as características do serviço (aparecerá como tópicos na tela do marketplace)"
                          error={
                            !!(errors.attributes && errors.attributes[index])
                          }
                          helperText={
                            errors.attributes &&
                            errors.attributes[index] &&
                            'Informe uma característica'
                          }
                        />
                      }
                      control={control}
                      name={`attributes.${index}`}
                      mode="onChange"
                    />
                    <Button
                      type="button"
                      disabled={index == 0}
                      color="secondary"
                      onClick={() => removeAttribute(index)}
                      startIcon={<MinusIcon size={20} />}
                    >
                      Remover
                    </Button>
                  </Box>
                ))}
              </Grid>
              {attributesFields.length < 10 && (
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => handleAppend()}
                  startIcon={<PlusIcon size={20} />}
                  className={classes.button}
                >
                  Adicionar mais características
                </Button>
              )}
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label="Defina o valor do serviço"
                      error={!!errors?.payment_type}
                      helperText={
                        errors?.payment_type
                          ? 'Selecione uma forma de precificação'
                          : ''
                      }
                    >
                      {selectOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  control={control}
                  name="payment_type"
                  mode="onChange"
                />
              </Grid>
              <Grid item xs={12}>
                {isDemandPrice
                  ? priceFields.map((option, index) => (
                      <Box key={index} className={classes.rowContainer}>
                        <Box className={classes.pricesContainer}>
                          <Controller
                            as={
                              <TextField
                                fullWidth
                                variant="outlined"
                                label="Preço"
                                error={
                                  !!(
                                    errors.prices && errors.prices[index]?.price
                                  )
                                }
                                helperText={
                                  errors.prices &&
                                  errors.prices[index]?.price &&
                                  'Informe um valor'
                                }
                              />
                            }
                            control={control}
                            name={`prices.${index}.price`}
                            mode="onChange"
                            onChange={([event]) => {
                              return helpers.formatters.currencyInput(
                                event.target.value,
                              )
                            }}
                          />
                          <Controller
                            as={
                              <TextField
                                fullWidth
                                variant="outlined"
                                label="Quantidade"
                                error={
                                  !!(
                                    errors.prices &&
                                    errors.prices[index]?.quantity
                                  )
                                }
                                helperText={
                                  errors.prices &&
                                  errors.prices[index]?.quantity &&
                                  'Informe a quantidade'
                                }
                              />
                            }
                            control={control}
                            name={`prices.${index}.quantity`}
                            mode="onChange"
                          />
                        </Box>
                        <Button
                          type="button"
                          color="secondary"
                          disabled={index === 0}
                          onClick={() => removePrice(index)}
                          startIcon={<MinusIcon size={20} />}
                        >
                          Remover
                        </Button>
                      </Box>
                    ))
                  : watch('payment_type') && (
                      <Grid item xs={5}>
                        <Controller
                          as={
                            <TextField
                              variant="outlined"
                              label="Preço"
                              error={
                                !!(errors.prices && errors.prices[0]?.price)
                              }
                              helperText={
                                (errors.prices &&
                                  errors.prices[0]?.price?.message) ||
                                ''
                              }
                            />
                          }
                          control={control}
                          name="prices.0.price"
                          mode="onChange"
                          onChange={([event]) => {
                            return helpers.formatters.currencyInput(
                              event.target.value,
                            )
                          }}
                        />
                      </Grid>
                    )}
              </Grid>
              {isDemandPrice && (
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => appendPrice('')}
                  startIcon={<PlusIcon size={20} />}
                  className={classes.button}
                >
                  Adicionar mais opções
                </Button>
              )}
            </Grid>
          </Box>
          <Divider />
          <Box py={2} px={2} display="flex" justifyContent="flex-end">
            <Box pr={1}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={history.goBack}
              >
                Cancelar
              </Button>
            </Box>
            <SuccessButton
              type="submit"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Salvar
            </SuccessButton>
          </Box>
        </form>
      )}
    </>
  )
}

PartnerServicesForm.propTypes = {
  partnerServiceId: PropTypes.string,
}

export default PartnerServicesForm
