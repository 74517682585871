import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 60,
    alignItems: 'center',
    marginBottom: 20,
  },
  button: {
    marginTop: -32,
    paddingLeft: 24,
    marginBottom: 10,
  },
  pricesContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
}))

export default styles
