import cpf from './cpf'
import cnpj from './cnpj'
import cep from './cep'
import date from './date'
import errorMessage from './errorMessage'
import phone from './phone'
import convertStringToInteger from './convertStringToInteger'
import hours from './hours'
import documentName from './documentName'
import currencyInput from './currencyInput'

const formatters = {
  cep,
  cnpj,
  convertStringToInteger,
  cpf,
  currencyInput,
  date,
  documentName,
  errorMessage,
  hours,
  phone,
}

export default formatters
