import * as yup from 'yup'

export const schema = yup.object().shape({
  companyServiceId: yup.object(),
  serviceCategory: yup.object(),
  description: yup
    .string()
    .required()
    .max(1000, 'A descrição deve conter no máximo 1000 caracteres.'),
  payment_type: yup.string().required('A forma de precificação é obrigatória'),
  attributes: yup.array().of(yup.string().required('Informe a característica')),
  prices: yup.array().of(
    yup.object().shape({
      price: yup.string().required('O valor é obrigatório'),
      price_id: yup.string(),
      quantity: yup.number().when('payment_type', {
        is: 'demand',
        then: yup.number().required('A quantidade é obrigatória.'),
      }),
    }),
  ),
})

export default schema
